.video-card {
    .img-emcee {
        img {
            transition: transform 1s;
            transform: scale(.99);
            &:hover {
                transform: scale(1);
            }
        }
    }
}