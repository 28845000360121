.dialog-delete-account {

  h2 {
    font-family: $font-bold;
    font-weight: bold;
    font-size: 20px;
  }

  p {
    color: $gray;
  }

  .radio-wrapper {
    margin-top: 16px;
    padding: 0 24px;
    border: 1px solid #EEEEEE;
    border-radius: 8px;

    .radio-detail {
      padding: 15px 0 20px 0;
      
      h3 { 
        margin: 0;
        margin-bottom: .5rem; 
        font-size: 18px; 
      }

      p { 
        margin: 0; 
        font-size: 14px;
      }
    }
  }
}