.custom-ads {
  position: relative;
  // padding: 0 15px;

  .section-search-ads {
    // margin: 0 15px;
    // padding: 0 15px;
    // max-width: 1000px;
    width: 100%;
    position: absolute;
    top: 45px; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  
    .product-ads {
      // border-left: 1px solid #e0e0e0;
      // border-right: 1px solid #e0e0e0;
      // border-bottom: 1px solid #e0e0e0;
      background-color: #ffffff;
      // border-radius: 0 0 8px 8px;
      border-radius: 8px;
      // border: 1px solid #e0e0e0;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      border-top: 0;

      .title-ads {
        text-align: left;
        padding: 8px;
        margin-bottom: 1;
  
        @include max-screen(603px) {
          text-align: center;
          padding: 8px 0;
          margin-bottom: 1;
        }
      }
    }
  
    .product-thumb {
      padding: 8px; // Adds spacing around items
      z-index: 10;
    }
  
    .border-link {
      // padding: 10px;
      border-radius: 8px;
      width: 100%;
      height: 100%;
      // border: 1px solid #e0e0e0;
      // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column; // Ensures it behaves like a card
      text-decoration: none;
      background: white;
      transition: all 0.2s ease-in-out;
  
      &:hover {
        // border-color: #717171;
        // box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      }
    }
  
    .border-link-container {
      display: flex;
      height: 100%;
      align-items: center;
    }
  
    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-height: 100px;
    }
  
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .MuiCardContent-root {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      font-size: 12px !important;
    }
  }
}