.category {
  margin: 0 auto;
  padding: 30px 15px;
  text-align: center;
  background-color: $cyan;
  line-height: 0;

  @include max-screen(533px) {
    .row-silde {
      display: block;
      // overflow-x: auto;
      // overflow-y: hidden;
      // white-space: nowrap;
    }
    .box-category {
      display: inline-block;
      width: 80px;
      vertical-align: top;
    }
  }

  .items-menu {
    margin: 0 8px;
    padding: 0;
    max-width: 90px;

    .items-radius {
      border-radius: 20px;
      background-color: $strcyan;
      padding: 15px;
      display: inline-block;
      cursor: pointer;
      @include transition(0.4s);
    }

    img {
      @include transition(0.4s);
    }

    p {
      font-family: $font-Medium;
      // @include font-size(12);
      line-height: 18px;
      padding: 15px 0;
      margin-top: 8px;
      margin-bottom: 0;
      white-space: normal;
    }

    &:hover {
      .items-radius {
        background-color: #3ca9a9;
        transform: scale(1.1);
      }

      /*img { transform: scale(1.2); }*/
    }
  }
}
.category-small {
  @extend .category;
  background-color: transparent;
  padding: 0;
  .items-menu {
    max-width: 100%;
    padding: 5px 0;
    .items-radius {
      max-width: 60px;
    }
    p {
      @include font-size(12);
    }
  }
}

.AntTabs {
  .AntTab-Btn {
    width: 200px;
    @include max-screen (533px) {
      width: 50%;
    }
  }
}