@font-face {
    font-family: 'NotoSans-Light';
    src: url('NotoSans-Light.eot');
    src: url('NotoSans-Light.eot?#iefix') format('embedded-opentype'),
        url('NotoSans-Light.woff2') format('woff2'),
        url('NotoSans-Light.woff') format('woff'),
        url('NotoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans-Regular';
    src: url('NotoSans-Regular.eot');
    src: url('NotoSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('NotoSans-Regular.woff2') format('woff2'),
        url('NotoSans-Regular.woff') format('woff'),
        url('NotoSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans-Medium';
    src: url('NotoSans-Medium.eot');
    src: url('NotoSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('NotoSans-Medium.woff2') format('woff2'),
        url('NotoSans-Medium.woff') format('woff'),
        url('NotoSans-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans-SemiBold';
    src: url('NotoSans-SemiBold.eot');
    src: url('NotoSans-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('NotoSans-SemiBold.woff2') format('woff2'),
        url('NotoSans-SemiBold.woff') format('woff'),
        url('NotoSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansThai-Light';
    src: url('NotoSansThai-Light.eot');
    src: url('NotoSansThai-Light.eot?#iefix') format('embedded-opentype'),
        url('NotoSansThai-Light.woff2') format('woff2'),
        url('NotoSansThai-Light.woff') format('woff'),
        url('NotoSansThai-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansThai-Regular';
    src: url('NotoSansThai-Regular.eot');
    src: url('NotoSansThai-Regular.eot?#iefix') format('embedded-opentype'),
        url('NotoSansThai-Regular.woff2') format('woff2'),
        url('NotoSansThai-Regular.woff') format('woff'),
        url('NotoSansThai-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansThai-Medium';
    src: url('NotoSansThai-Medium.eot');
    src: url('NotoSansThai-Medium.eot?#iefix') format('embedded-opentype'),
        url('NotoSansThai-Medium.woff2') format('woff2'),
        url('NotoSansThai-Medium.woff') format('woff'),
        url('NotoSansThai-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSansThai-SemiBold';
    src: url('NotoSansThai-SemiBold.eot');
    src: url('NotoSansThai-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('NotoSansThai-SemiBold.woff2') format('woff2'),
        url('NotoSansThai-SemiBold.woff') format('woff'),
        url('NotoSansThai-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

