.swal2-title {
  font-size: 20px !important;
  color: $black !important;
  font-family: $font-bold;
}

.swal2-styled.swal2-confirm {
  color: #ffffff !important;
  border: none !important;
  background-color: #db5a00 !important;
  font-family: $font-Regular !important;
  font-size: 14px !important;
}

.swal-custom-icon {
  color: #DB5A00 !important;

}

.swal2-icon.swal2-success .swal2-success-ring {
  border: .25em solid #DB5A00 !important;
  color: #DB5A00 !important;
  opacity: 0.3 !important;
}

.swal2-icon.swal2-success {
  border: .25em solid #DB5A00 !important;
  color: #DB5A00 !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #DB5A00 !important;
}

.swal2-styled.swal2-confirm:hover {
  background-color: #B34A00 !important;
  background-image: none !important;
}

.swal2-cancel.swal2-styled {
  color: #292929 !important;
  border: none !important;
  background-color: #dee2e6 !important;
  font-size: 14px !important;
  font-family: $font-Regular !important;
}

.swal2-cancel.swal2-styled:hover {
  background-color: #eaeef2 !important;
  background-image: none !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-styled.swal2-cancel:focus {
  box-shadow: none !important;
}

.swal-container {
  z-index: 9999 !important;
}

.swal-popup {
  z-index: 10000 !important;
}

.css-qhlwnk-MuiTabs-indicator {
  background-color: '#23BFBE' !important;
}

.swal2-html-container {
  font-size: 14px !important;
  color: #717275 !important;
}

.swal2-loader {
  border-color: #DB5A00 rgba(0, 0, 0, 0) #DB5A00 rgba(0, 0, 0, 0) !important;
}
