.petprofile {

  .align-center {
    margin: initial auto;
  }
  
  .img-mx-width {
    width: 100%; 
    max-width: 150px;
    max-height: 120px;
    height: auto;
    object-fit: cover;
  }

  .font-list {
    color: #747474;
    font-size: 15px;
  }

  .circle-img-pet { border-radius: 50%; }

  .circle-pet {
    margin: 8px;
    text-align: center;
    cursor: pointer;
  
    .circle-img-pet {
      border-radius: 50%;
      object-fit: cover;
      border: 3px solid transparent;
    }
  }

  .active-circle-pet {

    .circle-img-pet {
      border: 3px solid $link-hover-color;
    }

    p {
      color: $link-hover-color;
    }
  }

  .button-gray {
    border-radius: 4px;
    background-color: #DEE2E6;
    cursor: pointer;
    color: #292929;
    width: 100%;
    padding: .625em 1.1em;

    &:hover {
      background-color: #EAEEF2;
    }
  }

  .button-orange {
    border-radius: 4px;
    background-color: #DB5A00;
    cursor: pointer;
    color: #FFFFFF;
    width: 100%;
    padding: .625em 1.1em;

    &:hover {
      background-color: #B34A00;
    }
  }
}

.upload-img-wrapper {
  margin: 15px auto 30px auto;
  position: relative;
  display: inline-block;

  .btn-upload-overlay {
    border: 2px solid red;
    width: 100%; 
    height: 106px;
    position: absolute; 
    cursor: pointer;
    z-index: 9; 
    opacity: 0;
  }
}