@media (min-width: 600px) {
	.cs-container {
		max-width: 600px;
	}
}
@media (min-width: 720px) {
	.cs-container {
		max-width: 720px;
	}
}
@media (min-width: 1020px) {
	.cs-container {
		max-width: 1020px;
	}
}
@media (min-width: 1200px) {
	.cs-container {
		max-width: 1200px;
	}
}
@media (min-width: 1280px) {
	.cs-container {
		max-width: 1280px;
	}
}
@media (min-width: 1432px) {
	.cs-container {
		max-width: 1432px;
	}
}
@media (min-width: 1778px) {
	.cs-container {
		max-width: 1778px;
	}
	.cs-container.cs-site-content {
		max-width: 1432px;
	}
}