.partner {
    .image-container {
        @include max-screen (533px) {
            width: 100%;
        }
       
        span {
            @include max-screen (533px) {
                padding-top: 0 !important;
            }
        }
        .image {
            @include max-screen (533px) {
                object-fit: contain;
                width: 100% !important;
                position: relative !important;
                height: 250px !important;
                object-fit: cover !important;
            }
           
          }
    }
    &-content {
        .partner-banner {
            background-image: url(/assets/img/banner/bannerpartner2.webp);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 16% 0;
            height: 100%;
            position: relative;
            @include max-screen (533px) {
                background-image: url(/assets/img/banner/bannerpartner2-mobile.webp);
                background-size: 100%;
                padding: 36% 0;
            }
        }
        .tab-benefit, .tab-category, .tab-sharing, .tab-page, .tab-howto {
            padding-top: 4.5rem;
            padding-bottom: 4rem;
            .row-slide {
                @include max-screen (533px) {
                    overflow-x: auto;
                    overflow-y: hidden;
                    display: block;
                    white-space: nowrap;
                    padding: 0 15px;
                }
            }
        }
        .tab-page {
            .swiper-button-next, .swiper-button-prev {
                color: $strcyan;
            }
            .swiper-pagination-bullet-active {
                background: $strcyan;
            }
        }
        .page-desktop {
            display: block;
            @include max-screen (533px) {display: none;}
        }
        .page-mobile {
            display: none;
            @include max-screen (533px) {display: block;}
        }
        .box-benefit {
            @include max-screen (533px) {
                display: inline-block;
                width: 300px;
                white-space: break-spaces;
            }
        }
        .box-category {
            @include max-screen (533px) {
                display: inline-block;
                min-width: 1434px;
                white-space: break-spaces;
            }
        }
        .box-sharing {
            @include max-screen (533px) {
                display: inline-block;
                width: 300px;
                white-space: break-spaces;
            }
        }
        .box-howto1 {
            @include max-screen (533px) {
                display: inline-block;
                min-width: 700px;
                white-space: break-spaces;
            }
        }
        .box-howto2 {
            @include max-screen (533px) {
                display: inline-block;
                width: 350px;
                white-space: break-spaces;
            }
        }
        .box-contact {
            @include max-screen (533px) {
                display: inline-block;
                width: 240px;
                white-space: break-spaces;
            }
        }
        .navbar {
            padding-top: 0;
            padding-bottom: 0;
            overflow: hidden;
            #navbar-collapse-1 {
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-bottom: solid 1px $gray-light;
                background-color: white;
                margin: 0 -15px;
                ul {
                    @include max-screen (533px) {
                        overflow-x: auto;
                        overflow-y: hidden;
                        display: block;
                        white-space: nowrap;
                        padding: 0 15px;
                    }
                }
                .nav { 
                    li {
                        @include max-screen (533px) {
                            display: inline-block;
                            white-space: break-spaces;
                        }
                        .test5 {
                            @include max-screen (533px) {
                                padding-right: 3rem;
                            }
                        }
                        a {
                            cursor: pointer;
                            padding: 1rem;
                            border-bottom: solid 1px $gray-light;
                            &:hover {
                                color: $strcyan !important;
                                border-bottom: solid 1px $strcyan;
                            }
                        }
                    }
                }
                .active {
                    color: $strcyan !important;
                    border-bottom: solid 1px $strcyan !important;
                }
            }
        }  
        .img-desktop {
            display: block;
            @include max-screen (533px) {
                display: none;
             }
        }
        .img-mobile {
            display: none;
            @include max-screen (533px) {
                display: block;
             }
        }
        .benefit {
            h5 {font-family: NotoSans-SemiBold, NotoSansThai-SemiBold;}
        }
        .box-shadow {
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        }
    }
}