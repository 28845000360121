.banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
}
.banner-home, .banner-detail {
    &-desktop {
        display: block !important;
        @include max-screen (533px) {
            display: none !important;
        }
    }
    &-mobile {
        display: none !important;
        @include max-screen (533px) {
            display: block !important;
        }
    }
}


.holiday-day {
    color: red;
}

.fontBold {
    font-family: "NotoSans-SemiBold", "NotoSansThai-SemiBold" !important;
}


/* 
 * Always set the map height explicitly to define the size of the div element
 * that contains the map. 
 */
#map {
    height: 100%;
}

/* 
 * Optional: Makes the sample page fill the window. 
 */
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.controls {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid transparent;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    height: 29px;
    margin-left: 17px;
    margin-top: 10px;
    outline: none;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
}

.controls:focus {
    border-color: #4d90fe;
}

.title {
    font-weight: bold;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}