.profile-content {
  h4 {
    font-family: NotoSansThai-Regular, NotoSans-Regular;
    margin-bottom: 0;
  }
  .text-add {
    padding-top: 0.5rem;
    @include max-screen(533px) {
      padding-top: 0;
    }
  }
  .text-del {
    padding-top: 0.5rem;
    a {
      color: $red;
    }
    @include max-screen(533px) {
      padding-top: 0;
    }
  }
  .type-pet {
    @include max-screen(533px) {
      margin-top: 1rem;
    }
  }
}
.contentPet {
  h4 {
    font-family: NotoSansThai-Regular, NotoSans-Regular;
    line-height: 1.5;
    margin-bottom: 0;
  }
  .row-silde {
    @include max-screen(533px) {
      overflow-x: auto;
      overflow-y: hidden;
      display: block;
      white-space: nowrap;
    }
  }
  .pet-toggle {
    .btn-primary {
      background-color: transparent;
      border-color: transparent;
      &:hover {
        background-color: transparent;
        border-color: transparent;
      }
    }
    .btn-check:checked + .btn-primary {
      background-color: transparent;
      border-color: transparent;
      .img-thumbnail {
        border: 4px solid $yellow;
      }
      h6 {
        color: $yellow;
      }
    }
    &-btn {
      h6 {
        font-family: NotoSansThai-Regular, NotoSans-Regular;
        color: $black;
      }
      .img-thumbnail {
        border: 4px solid white;
        padding: 0;
      }
      &:hover {
        .img-thumbnail {
          border: 4px solid $yellow;
        }
        h6 {
          color: $yellow;
        }
      }
    }
    // .box-pet {

    // }
    // .btn-check:checked+.btn-primary {
    //     .box-pet {
    //         .img-thumbnail {
    //             border: 4px solid $yellow;
    //         }
    //         h6 {color: $yellow;}
    //     }
    // }
  }
  .box-pet {
    display: inline-block;
    // margin-top: 0.5rem;
    // margin-right: 2rem;
    cursor: pointer;
    @include max-screen(533px) {
      display: inline-block;
      vertical-align: top;
    }
    &-add {
      display: inline-block;
      margin-top: 0.5rem;
      margin-right: 2rem;
      cursor: pointer;
      // h6 {
      //     font-family: NotoSans-SemiBold, NotoSansThai-SemiBold;
      //     color: $gray;
      // }
    }
    .img-thumbnail {
      border: 4px solid white;
    }
    &:hover {
      .img-thumbnail {
        border: 4px solid $yellow;
      }
      h6 {
        color: $yellow;
      }
    }
    .icon-del {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      background-color: $red;
      border-radius: 50%;
      padding: 10px;
      color: white;
      cursor: pointer;
    }
    h6 {
      color: $black;
    }
    .img-thumbnail {
      padding: 0;
      object-fit: cover;
    }
  }
  .box-pet.active {
    .img-thumbnail {
      border: 4px solid $yellow;
    }
    h6 {
      color: $yellow;
    }
  }
  .nav-tabs {
    @include max-screen(533px) {
      overflow-x: auto;
      overflow-y: hidden;
      display: block;
      white-space: nowrap;
    }
    .nav-item {
      @include max-screen(533px) {
        display: inline-block;
        vertical-align: top;
      }
      .nav-link {
        color: $gray;
        &:hover {
          color: $strcyan;
          border-color: white white $strcyan;
        }
      }
      .nav-link.active {
        color: $strcyan;
        background-color: white;
        border-color: white white $strcyan;
      }
    }
  }
  .box-x-ray {
    .img-thumbnail {
      padding: 0;
      border: 1px solid #dee2e6;
      border-radius: 4;
      max-width: 100%;
      height: auto;
    }
  }
  .box-search {
    .card-image {
      img {
        max-width: 100%;
      }
    }
    p {
      font-size: 14px;
    }
    h5 {
      font-family: NotoSans-SemiBold, NotoSansThai-SemiBold;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
  .appointment {
    &-scroll {
      .box-time {
        background-color: $strcyan;
        color: white;
        border-radius: 4px;
        padding: 3px 5px;
        text-align: center;
      }
      .box-pin {
        @include max-screen(533px) {
          margin-top: 0.5rem;
        }
        .btn-pin {
          border: solid 1px $strcyan;
          color: $strcyan;
          border-radius: 4px;
          padding: 2px 5px;
          text-align: center;
        }
      }
      h6 {
        margin-bottom: 0.25rem;
      }
    }
  }
  .googlemap {
    iframe {
      width: 100%;
      height: 250px;
    }
  }
  .scrollbar {
    float: left;
    height: 290px;
    width: 100%;
    //background: #F5F5F5;
    overflow-y: scroll;
    overflow-x: hidden;
    .force-overflow {
      min-height: 450px;
      padding-right: 15px;
    }
  }
  #style-4::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: $gray-light;
  }

  #style-4::-webkit-scrollbar {
    width: 6px;
    background-color: $gray-light;
  }

  #style-4::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $gray;
  }
  .group-add {
    padding-top: 0.5rem;
    @include max-screen(533px) {
      padding-top: 0;
    }
  }
  .manage-group {
    .ctn-form {
      .input-group {
        input {
          border: 1px solid $gray-light;
          border-radius: 4px !important;
          padding: 8px 24px;
        }
        .btn-normal-yellow {
          padding: 0.375rem;
        }
      }
    }
    .img {
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
    h6 {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
    .btn-manage {
      font-family: NotoSansThai-Regular, NotoSans-Regular;
      padding: 0.375rem 0.75rem;
      margin-top: 0.5rem;
      max-width: 100%;
    }
    .friend {
      &-img {
        img {
          border-radius: 50%;
        }
      }
    }
  }
}

.contentPetAdd {
  .upload-img-pet {
    .ab-invisible {
      z-index: 1;
    }
    a {
      display: inline-block;
      position: relative;
    }
    .icon-upload {
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
      background-color: $yellow;
      border-radius: 50%;
      padding: 10px;
      color: white;
    }
    img {
      width: 100%;
      height: auto;
      min-width: 120px;
      min-height: 120px;
      max-width: 120px;
      max-height: 120px;
      border-radius: 50%;
      border: solid 4px $yellow;
      position: relative;
      overflow: hidden;
      object-fit: cover;
    }
  }
}

.record-physician {
  &-head {
    background-color: #f5f6f8;
    border-radius: 4px;
    .icon-syringe {
      svg {
        color: $strcyan;
      }
    }
  }
  &-icon {
    .cursor-hand {
      display: inline-block;
      margin-left: 0.5rem;
    }
  }
}

.history-cure {
  &-head-timeline {
    h5 {
      margin-bottom: 0;
    }
  }
  &-timeline {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 290px;
    .vtl {
      position: relative;
      padding: 10px 10px 10px 50px;
      box-sizing: border-box;
      &::before {
        content: "";
        width: 1px;
        background-color: $gray-light;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
      }
      .event {
        padding: 0 10px;
        position: relative;
        margin-bottom: 1rem;
        h6 {
          margin-bottom: 0.25rem;
        }
      }
      .date {
        &1 {
          background: $cyan;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          padding: 6px;
          text-align: center;
          color: white;
          font-family: NotoSans-SemiBold, NotoSansThai-SemiBold;
          position: absolute;
          top: 0%;
          left: -50px;
        }
        &2 {
          background: $strcyan;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          padding: 6px;
          text-align: center;
          color: white;
          font-family: NotoSans-SemiBold, NotoSansThai-SemiBold;
          position: absolute;
          top: 0%;
          left: -50px;
        }
        &3 {
          background: $yellow;
          width: 36px;
          height: 36px;
          border-radius: 50%;
          padding: 6px;
          text-align: center;
          color: white;
          font-family: NotoSans-SemiBold, NotoSansThai-SemiBold;
          position: absolute;
          top: 0%;
          left: -50px;
        }
      }
    }
  }
  .doctor-img {
    .img-thumbnail {
      padding: 0;
    }
  }
  .doctor-name {
    h5 {
      font-family: NotoSans-SemiBold, NotoSansThai-SemiBold;
      margin-bottom: 0.25rem;
    }
  }
}

.group-admin {
  .edit-btn {
    bottom: 30px !important;
    .btn-normal-yellow {
      border-radius: 4px;
      font-family: $font-bold;
    }
  }
  &-detail {
    small {
      color: $gray;
      a {
        color: $gray;
        &:hover {
          color: $yellow;
        }
      }
    }
    .nav-tabs {
      @include max-screen(533px) {
        overflow-x: auto;
        overflow-y: hidden;
        display: block;
        white-space: nowrap;
      }
      .nav-item {
        @include max-screen(533px) {
          display: inline-block;
          vertical-align: top;
        }
        .nav-link {
          color: $gray;
          &:hover {
            color: $strcyan;
            border-color: white white $strcyan;
          }
        }
        .nav-link.active {
          color: $strcyan;
          background-color: white;
          border-color: white white $strcyan;
        }
      }
    }
    .post {
      position: relative;
      textarea {
        padding: 18px 10px 70px 55px;
      }
      .img-write {
        position: absolute;
        top: 15px;
        left: 15px;
        img {
          border-radius: 50%;
        }
      }
      .img-upload {
        position: absolute;
        bottom: 10px;
        left: 15px;
      }
      .img-symbol {
        position: absolute;
        bottom: 10px;
        left: 50px;
      }
      .btn-normal-yellow {
        position: absolute;
        bottom: 15px;
        right: 15px;
        max-width: 120px;
        padding: 0.5rem;
        z-index: 2;
        text-align: center;
        border-radius: 4px;
        font-family: $font-bold;
      }
    }
  }
}

.create-group {
  ul {
    padding-left: 15px;
  }
  &-upload {
    position: relative;
    text-align: center;
    .ab-invisible {
      width: 100%;
      border: dashed 1px $gray-light;
      border-radius: 4px;
    }
  }
  .btn-normal-yellow {
    max-width: 100%;
    width: 100%;
  }
}
.tabs-place {
  .nav-link {
    color: $black;
    &:hover {
      color: $yellow;
    }
  }
}
.content_editor {
  width: 100%;
  height: 450px;
}
