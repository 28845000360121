footer {
    margin-top: 50px;

    .border-footer {
        background-image: url('https://cdn.maaboom.com/web/bg-border-footer.webp');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0;
        margin: 0 0 0;
        height: 100%;
        min-height: 5px;
        @include max-screen (533px) {
            min-height: 8px;
        }
    }

    .menu-list {
        text-align: left;

        h3 {
            font-family: $font-bold;
            text-transform: uppercase;
            font-size: 18px;
            margin-top: 1rem; margin-bottom: 0;
            color: $black;
        }
        
        ul {
            list-style: none;
            padding-inline-start: 0;
            margin: 0; padding: 1rem 0;

            li {
                padding: 4px 0;
                font-size: 14px;
            }
        }
    }
    .social-group {
        display: flex;
        @include max-screen (533px) {
            // display: none;
        }
        a {
            // padding: 10px 13px;
            border-radius: 5px;
            color: #fff;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            margin-right: 5px;
            text-align: center;
            // background-color: #23BEBE;
            svg {
                vertical-align: middle;
                // font-size: 1.25rem;;
            }                
        }
        .facebook {
            &:hover {
                background-color: #3b5998;
                transition: 0.4s;
            }
        }
        .twitter {
            &:hover {
                background-color: #00aced;
                transition: 0.4s;
            }
        }
        .instagram {
            &:hover {
                background-color: #675144;
                transition: 0.4s;
            }
        }
        .youtube {
            &:hover {
                background-color: #ff0000;
                transition: 0.4s;
            }
        }
        .line {
            &:hover {
                background-color: #06c755;
                transition: 0.4s;
            }
        }
        .ico {
            &-facebook {
                background-color: rgb(59, 89, 152);
            }
            &-twitter {
                background-color: rgb(0, 172, 237);
            }
            &-instagram {
                background: #d6249f;
                background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);;
                svg {
                    top: 5px;
                    position: relative;
                }
            }
            &-youtube {
                background-color: rgb(187, 0, 0);
                svg {
                    top: 5px;
                    position: relative;
                }
            }
            &-line {
                background-color: #38cd01;
                svg {
                    top: 5px;
                    position: relative;
                }
            }
            &-tiktok {
                border: 1px solid #ddd;
                color: #111111;
                filter: drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);
                svg {
                    top: 5px;
                    position: relative;
                }
            }
        }
    }
    .social-group-mobile {
        display: none;
        @include max-screen (533px) {
            display: flex;
        }
        .nav-item {
            a {
                padding: 10px 13px;
                border-radius: 5px;
                color: #fff;
                border-radius: 50%;
                height: 46px;
                width: 46px;
                // background-color: #23BEBE;
                svg {
                    vertical-align: middle;
                    font-size: 1.25rem;;
                }                
            }
            .facebook {
                &:hover {
                    background-color: #3b5998;
                    transition: 0.4s;
                }
            }
            .twitter {
                &:hover {
                    background-color: #00aced;
                    transition: 0.4s;
                }
            }
            .instagram {
                &:hover {
                    background-color: #675144;
                    transition: 0.4s;
                }
            }
            .youtube {
                &:hover {
                    background-color: #ff0000;
                    transition: 0.4s;
                }
            }
            .line {
                &:hover {
                    background-color: #06c755;
                    transition: 0.4s;
                }
            }
            .ico {
                &-facebook {
                    background-color: rgb(59, 89, 152);
                }
                &-twitter {
                    background-color: rgb(0, 172, 237);
                }
                &-instagram {
                    background: #d6249f;
                    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);;
                }
                &-youtube {
                    background-color: rgb(187, 0, 0);
                }
                &-line {
                    background-color: #38cd01;
                }
                &-tiktok {
                    border: 1px solid #ddd;
                    color: #111111;
                    filter: drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-2px -2px 0px #4DE8F4);
                }
            }
        }
    }
    .font-h4 {
        font-size: 1.5rem;
        font-family: NotoSans-SemiBold, NotoSansThai-SemiBold;
    }
    .footer-bottom {
        background-color: #DB5A00;
        // padding: 15px 0;
        // padding: 20px 0 15px;
        color: #FFFFFF;
        width: 100%;
        display: inline-block;
    }
    .footer-menu-desktop {
        display: block;
        .img-link {
            display: block;
            line-height: 0;
        }
        @include max-screen (533px) {
            display: none;
        }
    }
    .accordion {
        display: none;
        @include max-screen (533px) {
            display: block;
        }
        .accordion-item {
            border: 0;
            .accordion-button {
                padding: .5rem 0;
                color: $black;
                background-color:transparent;
                &:focus {
                  border-color:transparent;
                  box-shadow:none;
                }
                &::after {
                  background-image: url(/assets/img/icon/angle-down-solid.svg);
                  background-size: 50%;
                  background-position: center;
                  
                }
                &:not(.collapsed) {
                  color: $black;
                  box-shadow: none;
                  &::after {
                    background-image: url(/assets/img/icon/angle-down-solid.svg);
                    background-size: 50%;
                    background-position: center;
                  }
                }
            }
        }
        .accordion-body {padding: 0;}
    }
}
