.ctn-form {
  .input-group {
    input {
      border: 0;
      outline: 0;
      font-family: $font-Regular;
      @include font-size(getFontSize("xs"));
      // padding: 15px 24px;
      padding: 15px 48px 15px 24px;
      // border-radius: 4px 0 0 4px !important;
      @include max-screen(533px) {
        width: 100%;
        border-radius: 4px !important;
        margin-bottom: 0.75rem;
      }
    }

    .select-search {
      outline: 0;
      font-family: $font-Regular;
      @include font-size(getFontSize("xs"));
      padding: 15px 24px;
      border: 0;
      // margin: 1px;
      border-radius: 0 4px 4px 0 !important;
      /*border-left: 1px solid #f8f8f8;*/
      // border-radius: 5px !important;
      @include max-screen(533px) {
        max-width: 100%;
        width: 100%;
        border-radius: 4px !important;
        margin-bottom: 0.75rem;
      }

      &:active {
        outline: none;
      }

      &:focus {
        box-shadow: none;
      }
    }

    @include max-screen(533px) {
      button {
        width: 100%;
        min-width: 100%;
        margin-left: 0 !important;
      }
      // .mn-width-150 {
      //     min-width: 100px;
      // }
    }

    .position-ico-search {
      position: absolute;
      // left: 15px;
      right: 20px;
      top: 50%;
      z-index: 99;
      transform: translateY(-50%);
      @include font-size(15);
      color: $gray;
      @include max-screen(533px) {
        // top: 14%;
        top: 40%;
      }
    }
  }
}

/* login */
.login {
  background-color: #f7f7f7;
  margin-top: 6rem;
  margin-bottom: 6rem;
  h1 {
    font-family: $font-bold;
  }
  .form-control {
    padding: 0.75rem;
  }
  button {
    width: 100%;
    max-width: 100%;
    padding: 0.75rem;
  }
  .text-register {
    a {
      color: $strcyan;
      &:hover {
        color: $cyan;
      }
    }
  }
  .otp {
    display: inline-block;
    width: 14%;
    text-align: center;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.timer-zero {
  opacity: .5;
  font-size: 14px;
}

.timer {
  opacity: 1;
  cursor: pointer;
  font-size: 14px;
}


/* profile */
.profile {
  background-color: #f7f7f7;
  &-img {
    a {
      display: inline-block;
      position: relative;
    }
    img {
      width: 100%;
      height: auto;
      min-width: 160px;
      min-height: 160px;
      max-width: 160px;
      max-height: 160px;
      border-radius: 50%;
      border: solid 4px $yellow;
      position: relative;
      overflow: hidden;
      object-fit: cover;
    }
  }
  .ab-invisible {
    z-index: 1;
  }
  .icon-edit {
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 0;
    background-color: $yellow;
    border-radius: 50%;
    padding: 10px;
    color: white;
  }
  .follow-pets h4 {
    color: $strcyan;
  }
  &-sub-menu {
    .menu {
      position: relative;
      a {
        display: block;
        cursor: pointer;
        &:hover {
          color: $strcyan;
        }
      }
      .angleright {
        position: absolute;
        top: 4px;
        right: 15px;
      }
      .menu-item {
        @extend a;
      }
    }
    .active {
      a {
        color: $strcyan;
        font-family: $font-bold;
      }
    }
    /*.accordion-item {
      background-color:transparent;
      border: 0;
      &:first-of-type {
        .accordion-button {
          border-top-left-radius:0;
          border-top-right-radius:0;
        }   
      }
      &:last-of-type {
        .accordion-button.collapsed {
          border-bottom-left-radius:0;
          border-bottom-right-radius:0;
        }
      }
      .accordion-button {
        color: $black;
        background-color:transparent;
        &:focus {
          border-color:transparent;
          box-shadow:none;
        }
        &::after {
          background-image: url(/assets/img/icon/angle-down-solid.svg);
        }
        &:not(.collapsed) {
          color: $strcyan;
          &::after {
            background-image: url(/assets/img/icon/angle-down-solid-over.svg);
          }
        }
      }
    }*/
  }
  &-content {
    h2 {
      color: $cyan;
    }
  }
}

/* claim */
.claim {
  h1 {
    font-family: $font-bold;
  }
  &-bg {
    background-color: #f7f7f7;
    button {
      width: 100%;
      max-width: 100%;
      padding: 0.75rem;
    }
  }
}

/* validate */
.in-valid {
  color: #dc3545;
  margin-top: 0.25rem;
  font-size: 0.875em;
}

.border-invalid {
  border-color: #dc3545;
  // box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);

  &:focus {
    border-color: #dc3545 !important;
    box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%) !important;
  }
}
