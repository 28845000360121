.repone_test {
    border: 1px solid red;
    font-size: 15rem;
}

.responsive-table {
    width: 100%;
    display: flex;
    justify-content: center;
}

.responsive-table tr {
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: center;
}

.responsive-table td {
    //   border: 1px solid black;
    padding: 20px;
    margin: 10px;
    text-align: center;
}


@media screen and (max-width: 600px) {

    .responsive-table,
    .responsive-table tr,
    .responsive-table td {
        display: block;
        text-align: center;
        // width: 100%;
    }

    .responsive-table img {
        width: 60%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}


.responsive-header img {
    width: 10%;
    height: auto;
    border-color: brown;
}

@media screen and (max-width: 600px) {
    .responsive-header img {
        width: 100%;
    }
}




.responsive-tableportal {
    width: 100%;
    display: flex;
    justify-content: center;
}

.responsive-tableportal tr {
    /* display: flex; */
    flex-wrap: wrap;
    justify-content: center;
}

.responsive-tableportal td {
    //   border: 1px solid black;
    padding: 20px;
    margin: 10px;
    text-align: center;
}

.responsive-tableportal img {
    width: 25%;
        display: block;
        margin-left: auto;
        margin-right: auto;
}


@media screen and (max-width: 600px) {

    .responsive-tableportal,
    .responsive-tableportal tr,
    .responsive-tableportal td {
        display: block;
        text-align: center;
        // width: 100%;
    }

    .responsive-tableportal img {
        width: 60%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}