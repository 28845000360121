.img-profile-highlight {
  img {
    max-width: 100px;
  }
  @include max-screen(533px) {
    // position: absolute;
    // top: -40px;
    // left: 2rem;

    img {
      max-width: 80px !important;
    }
  }
}
.card-image-fill {
  object-fit: cover;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  height: 100%;
  min-height: 200px;
  max-height: 200px;
}
.img-radius {
  border-radius: 50%;
}
.card-images {
  min-height: 285px;
  @include max-screen(533px) {
    min-height: 100%;
  }
}
.ctn-card {
  .hightlight-card {
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .card-box {
    .card-body {
      min-height: 165px;
    
      small {
        position: absolute;
        bottom: 0;
        padding-bottom: 10px;
      }
    }
  }
  .img-profile {
    // display: inline-block;
    @include max-screen(533px) {
      position: absolute;
      top: -50px;
    }
    img {
      max-width: 100px;
      border-radius: 50%;
      background-color: transparent;
      padding: 0;
      @include max-screen(533px) {
        max-width: 80px;
      }
    }
  }
  .card {
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // height: 100%;

    .card-img {
      border-radius: 0;
      // min-height: 285px;
      @include max-screen(533px) {
        min-height: 100%;
      }
    }
  }
  .horizon-card {
    position: relative;
    //min-height: 55px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -35%);
    @include max-screen(533px) {
      transform: translate(-50%, 0);
      margin: 0 0 0px;
    }
    label {
      position: absolute;
      top: 50%;
      transform: translateY(-25%);
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      @include max-screen(1366px) {
        padding-left: 1rem !important;
      }
      @include max-screen(533px) {
        position: relative;
        top: auto;
        transform: translateY(0%);
        padding-left: 0 !important;
      }
    }
    .img-profile {
      // position: absolute;
      // top: -60px;
      // left: 20px;
      img {
        border-radius: 50%;
        background-color: transparent;
      }
    }
  }
    .css-10rcck9-MuiCardContent-root:last-child {
      padding: 0;
    }
  .vertical-card-sm {
    min-height: 250px;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -17%);
    @include max-screen(533px) {
      transform: translate(-50%, 0);
      margin: 0 0 30px;
      min-height: auto;
    }
    .img-profile {
      img {
        border-radius: 50%;
        background-color: transparent;
      }
    }
  }
  .card-body {
    padding: 0 20px;
  }
  .card-profile {
    .card-img-top {
      width: 100%;
      min-width: 100px;
      max-width: 120px;
      margin: 0 auto;
      display: block;
    }
  }
  label {
    @include inline-block();
    vertical-align: bottom;
    //margin-bottom: 1rem;
    @include max-screen(533px) {
      margin-bottom: 0.5rem;
    }
  }

  .tag-label {
    border: 0;
    outline: 0;
    background-color: $lightcyan;
    font-family: $font-Regular;
    @include font-size(getFontSize("ss"));
    padding: 3px 10px;
    color: $black;
    transition: 0.4s;
    cursor: pointer;
    margin-top: 0.75rem;
    @include max-screen(533px) {
      margin-top: 0.5rem;
    }

    a {
      color: inherit;
    }

    &:hover {
      background-color: $strcyan;
      color: $black;
    }
  }
  /*a {
    color: $link-color;
    text-decoration: unset;
    
    &:hover {
      color: $link-hover-color;
      text-decoration: unset;
    }
  }*/
  h2 {
    //color: $black;
    //@include font-size(getFontSize('md'));
    font-family: $font-bold;
    @include text-clamp($lines: 2);
  }
  h3 {
    @include text-clamp($lines: 2);
  }
  h4 {
    //color: $black;
    @include text-clamp($lines: 2);
  }
  .font-h4 {
    @extend h4;
  }
  p {
    // color: $gray;
    font-family: $font-Regular;
    @include font-size(getFontSize("xs"));
    @include text-clamp($lines: 2);
  }
  small {
    @include text-clamp($lines: 2);
  }
}
.search-card {
  .card-body {
    min-height: 200px;
    .tag-group {
      position: absolute;
      bottom: 0;
      padding-bottom: 10px;
    }
  }
}


.CardProduct{
  width: 100%;
  height: auto;
}
.CardProfile {
  .Avatar-img {
    max-width: 120px;
    width: 120px;
    height: 120px;
    @include max-screen (533px) {
      max-width: 80px;
      height: 80px;
  }
  }
  .Card-btn {
    width: 200px;
    @include max-screen (533px) {
      width: 100%;
  }
  }
}
.CardProduct {
  &-desktop {
      display: block;
      @include max-screen (533px) {
          display: none;
      }
  }
  &-mobile {
      display: none;
      @include max-screen (533px) {
          display: block;
      }
  }
}
.ProductInCart {
  &-desktop {
      display: block;
      @include max-screen (533px) {
          display: none;
      }
  }
  &-mobile {
      display: none;
      @include max-screen (533px) {
          display: block;
      }
  }
}
.order-rightBox {
  padding: 1rem;
  @include max-screen (533px) {
    padding: 1rem 0;
  }
}
.product-detail {
  img {
    width: auto;
    height: auto;
    max-width: 100%;

  }
}


.swiper-slide img {
  // height: 100%; /* ตั้งความสูงเป็น 100% เพื่อให้รูปภาพทุกรูปมีความสูงเท่ากัน */
}

// .css-ucct6l-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type){
//   border-left:"none" ;
// }


