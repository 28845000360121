.search-card {
  .card-image {
    position: relative;
    .img-ribbon {
      position: absolute;
      top: 10px;
      left: 0;
      z-index: 99;
    }
  }
}
.search-detail {
  .article-section {
    iframe {
      @include max-screen(533px) {
        width: 100%;
      }
    }
    @include max-screen(533px) {
      a {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
  .img-top {
    position: absolute;
    width: 100%;
    z-index: 1;
    @include max-screen(533px) {
      z-index: 0;
    }
    .edit-btn {
      position: absolute;
      bottom: 15px;
      right: 15px;
      z-index: 99;
      width: 200px;
    }
  }
  .image-container {
    @include max-screen(533px) {
      width: 100%;
    }

    span {
      @include max-screen(533px) {
        padding-top: 0 !important;
      }
    }
    .image {
      @include max-screen(533px) {
        object-fit: contain;
        width: 100% !important;
        position: relative !important;
        height: 250px !important;
        object-fit: cover !important;
      }
    }
  }
  .img-blur img {
    filter: blur(1.5rem);
    @include max-screen(533px) {
      z-index: -1;
    }
  }
  .banner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    .bannerCover {
      background-color: #242429;
      opacity: 0.4;
    }
  }
  .metabar {
    &-desktop {
      display: block;
      position: sticky;
      transition: 0.4s;
      // top: 20px;
      top: 120px;
      @include max-screen(533px) {
        display: none;
      }
    }
    &-mobile {
      display: none;
      @include max-screen(533px) {
        display: block;
        .text-center {
          text-align: left !important;
        }
        .list-group {
          display: block;
          margin-top: 0.5rem;
          .list-group-item {
            display: inline-block;
            padding: 0.5rem;
          }
        }
      }
    }
  }
  .widget-desktop {
    display: block;
    @include max-screen(533px) {
      display: none;
    }
  }
  .widget-mobile {
    display: none;
    @include max-screen(533px) {
      display: block;
    }
  }
  .social-box {
    .btn-social {
      a {
        display: inherit;
        padding: 0px;
        &::before {
          display: inline-block;
          font-style: normal;
          font-variant: normal;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
        }
      }
      ul {
        padding-left: 0;
      }
      li {
        display: inline-flex;
        align-items: center;
        color: #fff;
        margin: 0 0 5px;
        padding: 5px 8px;
        border-radius: 5px;
        text-decoration: none;
        cursor: pointer;
        width: 100px;
        min-width: 120px;
        @include font-size(14);
      }
      .li {
        &_wbFkic8 {
          a {
            &::before {
              content: "";
              background-image: url(/assets/img/icon/facebook.png);
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 30px 30px;
              height: 30px;
              width: 30px;
              position: relative;
              top: 0;
              left: 0;
            }
          }
          background-color: rgb(59, 89, 152);
        }
        &_goYjo3C {
          background-color: $strcyan;
          a {
            &::before {
              content: "";
              background-image: url(/assets/img/icon/footprint.png);
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 20px 20px;
              height: 30px;
              width: 30px;
              position: relative;
              top: 0;
              left: 0;
            }
          }
        }
        &_twitter {
          a {
            &::before {
              content: "";
              background-image: url(/assets/img/icon/twitter.png);
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 30px 30px;
              height: 30px;
              width: 30px;
              position: relative;
              top: 0;
              left: 0;
            }
          }
          background-color: rgb(0, 172, 237);
        }
        &_I0qnC3w {
          a {
            &::before {
              content: "";
              background-image: url(/assets/img/icon/instagram.png);
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 25px 25px;
              height: 30px;
              width: 30px;
              position: relative;
              top: 0;
              left: 0;
            }
          }
          background: #d6249f;
          background: radial-gradient(
            circle at 30% 107%,
            #fdf497 0%,
            #fdf497 5%,
            #fd5949 45%,
            #d6249f 60%,
            #285aeb 90%
          );
        }
        &_youtube {
          a {
            &::before {
              content: "";
              background-image: url(/assets/img/icon/youtube.png);
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 20px 20px;
              height: 30px;
              width: 30px;
              position: relative;
              top: 0;
              left: 0;
            }
          }
          background-color: rgb(187, 0, 0);
        }
        &_CCXEuHO {
          a {
            &::before {
              content: "";
              background-image: url(/assets/img/icon/line.png);
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 30px 30px;
              height: 30px;
              width: 30px;
              position: relative;
              top: 0;
              left: 0;
            }
          }
          background-color: #38cd01;
        }
      }
      span {
        @include font-size(14);
      }
    }
  }
  .dropzone {
    border: 2px dashed #3498db;
    padding: 50px;
    background-color: #fafafa;
    img {
      width: 50px;
      display: block;
      margin: auto;
      opacity: 0.6;
      margin-bottom: 15px;
    }
    p {
      text-align: center;
    }
  }
  .thumbEdit {
    display: inline-flex;
    width: 100%;
    min-height: 440px;
    height: 440px;
    padding: 0px;
    position: relative;
    box-sizing: border-box;
    z-index: 9;
    justify-content: center;
    @include max-screen(1366px) {
      min-height: 400px;
      height: 400px;
    }
  }
  .edit-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -35%);
    width: 100%;
    padding: 2px;
    text-align: center;
    background-color: #f5ce63c4;
    color: #444;
    font-size: 18px;
  }
  .banner-btn {
    text-align: center;
    color: #444;
  }
  .btn-primary.disabled,
  .btn-primary:disabled {
    background-color: #dee2e6;
    border-color: #dee2e6;
    color: #292929;
  }
  .modalForm {
    text-align: left;
    width: 60%;
    margin: 0 auto;
  }
}
.tag-category {
  border-radius: 4px;
  background-color: $strcyan;
  width: 40px;
  height: 40px;
  padding: 10px;
}
.box-calltoaction {
  @include max-screen(533px) {
    .btn-norm-cyen {
      max-width: 100%;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .btn-normal-yellow {
      max-width: 100%;
      border-radius: 4px;
    }
    .btn-normal-gray-light {
      max-width: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
.claim {
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #23bfbe;
  }
  .nav-link {
    color: grey;
  }
}
