section.about {
    padding: 32px 0 48px;
    p {margin: 1rem auto 3rem;}
    .icon {
        width: 80px;
        height: 80px;
        margin: 0 auto;
        padding: 20px;
        border: solid 1px $gray-light;
        border-radius: 50%;
        .svg-inline--fa {
            width: 100%;
            height: 100%;
            color: $gray;
        }
    }
    .text {margin: 2rem auto 3rem;}
    #map {width: 100%; height: 450px;}
}
 
section.tt{
    color:#ffffff;
}

section.about .icon .svg-inline--fa {width: 100%; height: 100%;}
section.about #map {width: 100%; height: 450px;}
