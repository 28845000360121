.article {

    &-detail {
        .author-img {
            max-width: 120px;
        }
        .FacebookProvider {
            @include max-screen (533px) {
                padding: 0;
            }
        }
        .metabar {
            &-desktop {
                display: block;
                position: sticky;
                transition: 0.4s;
                top: 20px;
                @include max-screen (533px) {
                    display: none;
                }
            }
            &-mobile {
                display: none;
                @include max-screen (533px) {
                    display: block;
                    .text-center {
                        text-align: left!important;
                    }
                    .list-group {
                        display: block;
                        margin-top: .5rem;
                        .list-group-item {
                            display:inline-block;
                            padding: .5rem;
                        }
                    }
                }
            }
            // &-inner {
            //     display: flex;
            //     position: sticky;
            //     transition: 0.4s;
            //     top: 20px;
            //     @include max-screen (533px) {
            //         top: 32px;
            //         margin-bottom: 1rem !important;
            //     }

            //     .list-group {
            //         display:block;
            //         margin: 0 auto;

            //         .list-group-item {
            //             display:inline-block;
            //         }
            //     }

            // }
        }

    }
}
.article-section {
    img {
        max-width: 100%;
        height: auto;
    }
}