.card-skeleton {
  border-radius: 5px;

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;

    @include max-screen(1366px){
      max-width: 75px;
      max-height: 75px;
    }
  }

  .sk-img {
    width: 100%; 
    min-height: 365px;
  }
}

.card-skeleton-img {
  width: 200px;
  height: 200px;
}

.card-skeleton .left-col {
  margin-right: 1rem;
}

.card-skeleton .right-col {
  flex: 1;
}

.card-skeleton-highlight {
  border-radius: 0;
  border: 0;
  &-img {
    height: 350px;
    border-radius: 0 !important;
    @include max-screen(533px) {
      height: 240px;
    }
  }
  .horizon-card {
    z-index: 2;
    // transform: translate(-50%, -27%);

    @include max-screen(533px){
      // transform: translate(-50%, 0%);
      .card-title {
        padding-top: 10px;
      }
    }

    .card-title {
      width: 100%;
      min-height: 30px;
      @include inline-block();

      .react-loading-skeleton {
        width: 100%;
        height: 100%;
      }
    }
    .avatar {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: solid 1px #fff;
      @include max-screen(533px){
        min-width: 80px;
        min-height: 80px;
      }
    }
    .tag {
      width: 80px;
      height: 27px;
      @include max-screen(533px){
        margin-left: 110px;
      }
    }
  }
}

.card { 
  border: none;
}