.card-discount {
    .css-19kzrtu {
        padding: 0;
    }
}
.txt-discount {
    color: $gray-light;
    @include font-size(12);
}
.itemThumbs {
  padding: 10px 0px;
  margin-right: 5px;
}
.btn-toggle {
  @include max-screen (533px) {
    display: block;
    button {
      margin: 5px 5px 5px 0px;
    }
}
}
//////////////////////// Button //////////////////////// 
.btn {
    border: 0;
    outline: 0;
    font-family: $font-bold;
    @include transition(0.4s);
    text-align: center;
    cursor: pointer;
    &-normal-yellow {
      background-color: $yellow;
      color: $black;
      padding: .75rem;
      max-width: 180px;
      &:hover {
        background-color: #ffca2c;
         border-color: #ffc720;
        color: $black;
      }
      &:active {
        color: $black;
        background-color: #ffca2c;
         border-color: #ffc720;
        border: 0;
        outline: none;
      }
      &:focus {
        color: $black;
        background-color: #ffca2c;
         border-color: #ffc720;
        box-shadow: none;
      }
    }
    &-normal-gray-light {
      background-color: $gray-light;
      color: $black;
      padding: .75rem;
      max-width: 180px;
      &:hover {
        color: $black;
        background-color: #dee2e6b5;
        border-color: #dee2e6b5;
      }
      &:active {
        color: $black;
        background-color: #dee2e6b5;
        border-color: #dee2e6b5;
        border: 0;
        outline: none;
      }
      &:focus {
        color: $black;
        background-color: #dee2e6b5;
        border-color: #dee2e6b5;
        box-shadow: none;
      }
    }
    &-norm-cyen {
      background-color: $cyan;
      color: $black;
      padding: .75rem;
      max-width: 180px;
      &:hover {
        background-color: #0dcaf0;
        border-color: #0dcaf0;
        border: 0;
        color: $black;
      }
      &:active {
        background-color: #0dcaf0;
        border-color: #0dcaf0;
        border: 0;
        color: $black;
        outline: none;
      }
      &:focus {
        color: $black;
        background-color: #0dcaf0;
        border-color: #0dcaf0;
        box-shadow: none;
      }
        }
  }



  @media print {
    .print-button{
      display: none;
    }
    
  }


     .no-hover {
       pointer-events: none;
     }
