.btn-toggle {
  button {
    &:not(:first-of-type) {
      border: 1px solid !important;
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
  }

}

.product-card {
  position: relative;

  .MuiCardContent-root {
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiTypography-h3 {
    @include text-clamp($lines: 2);
    margin-bottom: 0;
  }

  .MuiTypography-h5 {
    div {
      font-family: $font-bold;
    }
  }
}

.box-shadow-center {
  position: relative;
  box-shadow: 0 0 24px  hsla(210, 8%, 62%, .2) !important;
}

.product-card-best {
  position: relative;
  box-shadow: 0 0 24px hsla(210, 8%, 62%, .2) !important;
  height: 100%;
  // display: inline-block;

  @include max-screen(1199px) {
    // min-height: 350px;
  }

  // box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  .MuiCardContent-root {
    min-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
    // height: 108px !important;
  
  }


    .product-card-Coupon {
      position: relative;
      box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2) !important;
  
      // box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
      .MuiCardContent-root {
        min-height: 108px;
        padding-top: 0;
        padding-bottom: 0;
  
      }
    }

  .MuiTypography-h3 {
    @include text-clamp($lines: 2);
    margin-bottom: 0;
    @include font-size(16);
  }

  .MuiTypography-h5 {

    // font-family: $font-bold;
    div {
      font-family: $font-bold;
    }
  }
}

.product-card-best-1920 {
  position: relative;
  box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2) !important;

  // box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  .MuiCardContent-root {
    min-height: 64px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiTypography-h3 {
    @include text-clamp($lines: 2);
    margin-bottom: 0;
    @include font-size(16);
  }

  .MuiTypography-h5 {

    // font-family: $font-bold;
    div {
      font-family: $font-bold;
    }
  }
}

.product-card-best-moblie {
  position: relative;
  // box-shadow: 0 8px 24px hsla(200, 20%, 97%, 0.2) !important;
  box-shadow:0 8px 24px hsla(210, 8%, 88%, 0.2) !important;

  // box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  .MuiCardContent-root {
    // min-height: 108px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiTypography-h3 {
    @include text-clamp($lines: 2);
    margin-bottom: 0;
    @include font-size(16);
  }

  .MuiTypography-h5 {

    // font-family: $font-bold;
    div {
      font-family: $font-bold;
    }
  }
}
.product-card-best-moblie-Article {
  position: relative;

   
  box-shadow: 0 8px 24px hsla(210, 8%, 88%, 0.2) !important;

 
  .MuiCardContent-root {
    min-height: 35px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .MuiTypography-h3 {
    @include text-clamp($lines: 2);
    margin-bottom: 0;
    @include font-size(16);
  }

  .MuiTypography-h5 {

    
    div {
      font-family: $font-bold;
    }
  }
}

// Ribbon
[class^="ribbon-"] {
  position: relative;
  margin-bottom: 80px;

  &:before,
  &:after {
    content: "";
    position: absolute;
  }
}


// Ribbon-1
.ribbon-1 {
  width: 30px;
  height: 30px;
  top: -10px;
  right: 20px;
  position: absolute;
  background-image: url(../../../public/assets/images/icon/discount.png);
  background-repeat: no-repeat;
  background-size: contain;
  padding: 2px 5px 0 5px;
  margin: 10px 0;
  text-align: center;
  @include text-clamp($lines: 1);
  font-size: 12px;

  // &:before {
  //   @include triangle(6px, darken(#D72E2D, 15%), down-left);
  //   right: -6px;
  // }
  // &:after {
  //   @include triangle(30px 15px, transparent #D72E2D, up);
  //   bottom: -15px;
  // }
  p {
    font-size: 12px;
    color: #fff;
    font-weight: 700;
    position: relative;
    text-align: center;
  }
}

.img-responsive {
  width: 100%;
  height: auto;
}

.chip-discount {
  background-image: url(../../../public/assets/images/icon/bg-discount.png);
  background-repeat: no-repeat;
  background-size: contain;
  height: auto;
  max-width: 75px;
  border: unset;
  color: #D72E2D;
  background-color: unset;
  padding: 2px 5px 0 5px;
  margin: 0px 0;
  text-align: center;
  @include text-clamp($lines: 1);
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-discount {
  background-image: url(../../../public/assets/images/icon/card-discount.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 65%;
  height: auto;
  width: 100%;
  border: unset;

  .card-left {
    border-left: 3px solid rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }

  .card-right {
    border-right: 3px solid rgba(0, 0, 0, 0.04);
    border-radius: 5px;
  }
}

.card-discount-logo {
  background-image: url(../../../public/assets/images/logo/discountLogo.png);
  height: auto;
  width: auto;
  // border: unset;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  // display: flex;
  // padding: 5px 5px;
  // margin-right: 10px;
  border-right: 2px solid #F2F3F4;
  border-radius: 15px;
}

.card-discount-logo-condition {
  background-image: url(../../../public/assets/images/logo/discountLogo.png);
  height: auto;
  width: auto;
  border: unset;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  border-right: 2px solid #F2F3F4;
  border-radius: 15px;
}



.mySwiper {
  box-sizing: border-box;
}

.mySwiper .swiper-slide {
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.mySwiper {
  padding: 10px 0;
}

.mySwiper2 {
  // margin-bottom: 10px;

  .swiper-wrapper {
    align-items: center;
    // background-color: #000000;
  }
}