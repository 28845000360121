.community {
  &_story {
    .no-active {
      position: relative;
      border: solid 2px transparent;
    }
    .icon-add {
      position: absolute;
      right: 0;
      bottom: -20px;
      z-index: 1;
      font-size: 24px;
      color: $yellow;
      @include transition(0.4s);
      &:hover {
        color: #f5b718;
      }
    }
    a {
      position: relative;
      margin-right: 16px;
    }
    img {
      border-radius: 50%;
      max-width: 90px;
      border: solid 2px $strcyan;
    }
  }
  &_commment {
    position: relative;
    border: solid 1px $gray-light;
    border-radius: 4px;
    img {
      border-radius: 50%;
      max-width: 100%;
    }
    textarea {
      border: 0;
      padding-left: 0;
      margin-bottom: 24px;
    }
    .pl0 {
      padding-left: 0;
    }
    .icon-post {
      font-size: 24px;
      color: $cyan;
      margin-right: 0.5rem;
      @include transition(0.4s);
      &:hover {
        color: $strcyan;
      }
    }
    .btn-post {
      position: absolute;
      bottom: 0.75rem;
      right: 0.75rem;
      background-color: $yellow;
      color: $black;
      min-width: 124px;
      padding: 0.75rem;
    }
    &_detail {
      .icon-heart {
        color: $strcyan;
      }
      .text-heart {
        color: $gray;
      }
      .text-comment {
        color: $gray;
        text-align: right;
        button {
          background-color: transparent !important;
          border: 0;
        }
      }
      .social {
        .icon-heart {
          color: #ff4e4d;
          margin-right: 8px;
        }
        .text-heart {
          color: #ff4e4d;
        }
        button {
          background-color: transparent !important;
          border: 0;
        }
        .icon-comment,
        .icon-share,
        button {
          margin-right: 8px;
        }
        a {
          margin-right: 16px;
        }
      }
      .reply-comment {
        .box {
          background-color: #f5f6f8;
          border-radius: 4px;
          img {
            border-radius: 50%;
            max-width: 100%;
          }
          h5 {
            margin-bottom: 0;
          }
          p {
            margin-bottom: 0;
            padding: 0.25rem 0;
          }
          .icon-report {
            position: absolute;
            right: 15px;
            color: $gray;
          }
        }
      }
      .card-body {
        padding: 0;
      }
    }
  }
  &_post {
    img {
      border-radius: 50%;
      max-width: 100%;
    }
    .name {
      h4 {
        margin-bottom: 0;
      }
    }
    .icon-report {
      color: $gray;
    }
    .icon-edit {
      color: $gray;
      margin-right: 8px;
    }
    .icon-trash {
      color: $gray;
    }
  }
  &_pic {
    img {
      width: 100%;
      max-width: 100%;
    }
  }
  &_recommend {
    img.profile {
      border-radius: 50%;
      max-width: 100%;
    }
    h6 {
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
  &_group {
    img {
      border-radius: 10px;
      max-width: 100%;
    }
  }
  &_activity {
    .title-txt {
      span {
        &:nth-child(3n + 1) {
          color: $cyan;
        }
        &:nth-child(3n + 2) {
          color: $yellow;
        }
      }
    }
    .horizon-card {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -35%);
      .img-profile {
        img {
          border-radius: 50%;
          background-color: transparent;
        }
      }
      .tag-label {
        border: 0;
        outline: 0;
        background-color: #c2edef;
        font-family: NotoSansThai-Regular, NotoSans-Regular;
        font-size: 14px;
        padding: 3px 10px;
        color: #292929;
        transition: 0.4s;
        cursor: pointer;
        margin-top: 0.75rem;
      }
      label {
        position: absolute;
        top: 50%;
        transform: translateY(-25%);
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
  &_profile {
    &_head {
      img {
        border-radius: 50%;
        max-width: 100%;
      }
      h4 {
        // margin-top: 1rem;
        margin-bottom: 0.25rem;
      }
      .btn-setting {
        font-size: 18px;
      }
      .box-pet {
        display: inline-block;
        cursor: pointer;
        .img-thumbnail {
          border: 4px solid white;
          padding: 0;
          object-fit: cover;
        }
        &:hover {
          .img-thumbnail {
            border: 4px solid $yellow;
          }
          h6 {
            color: $yellow;
          }
        }
      }
    }
    &_socail {
      margin-top: 1rem;
      span {
        font-family: $font-bold;
        color: $strcyan;
      }
    }
    &_post {
      .community_pic {
        img {
          border-radius: 0;
          max-width: 100%;
        }
      }
      .photo {
        img {
          border-radius: 0;
          max-width: 100%;
        }
      }
    }
    .nav-tabs {
      .nav-link {
        color: $gray;
        border: 2px solid transparent;
      }
      .nav-link.active {
        color: $strcyan;
        border-color: #ffffff #ffffff $strcyan;
      }
    }
  }
}
