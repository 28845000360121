.facebook-img {
    img{
        max-width: 100%;
        max-height: auto;
        width: 100% !important;
        height: auto;
    }
}

.rdrMonthAndYearWrapper {
    height: 40px !important;
    padding-top: 0 !important;
}

.rdrMonth {
    padding: 0 !important;

    @include max-screen(375px) {
        width: auto !important;
    }
}

.swiper-button-prev, .swiper-rtl .swiper-button-next {
    left: 1px !important;
}

.swiper-button-next, .swiper-rtl .swiper-button-prev {
    right: 1px !important;
}