




.bgcprofile {
  background-color: #f8f9f9;
  height:100%;
    // height: auto;

  .imgbg {
    margin: 0 auto;
    text-align: center;
    padding: 1rem 0;
    border: 4px solid #F4D03F;
    border-radius: 100%;
    width: 130px;
    height: 100%;
    background-image: url(../../../public/assets/images/logo/bird.png);
    background-size: 100% 100%;
    max-height: 100%;
  }
  .imgText {
    margin: auto;
    // text-align: right;
    padding: 1rem 0;
  }

  @include max-screen(1200px) {
    display: none;
  }
}

.menu-profile-mobile {
  display: none;

  @include max-screen(1200px) {
    display: block;
  }
}

// .wrapper{
//   height: 80px;
//   width: 80px;
//   position: relative;
//    border: 5px solid #F4D03F;
//    border-radius: 100%;
// }

.linktable {
  display: contents;
  width: 100%;
  vertical-align: top;
}

.bgcoupon {
  background-color: #f8f9f9;
  border-radius: 4px;
}
.bgnavbar{
  background-color: #f8f9f9;
  border-radius: 10px;
}
.TextArea
{
  width: auto;
}

.HrColor{
  background-color: #F4D03F;
}



// .coupon-card {
//   border-radius: 15px;
//   align-items: center;
// }

// .circle1,
// .circle2 {
//   background: #fdfefe;
//   border: 1px solid #d9d9d9;
//   width: 50px;
//   height: 50px;
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   top: -25px;
//   right: auto;
// }
// .circle1 {
//   border: 1px solid #d9d9d9;
//    border-radius:0 0  12rem 12rem ;
// }
// .circle2 {
//   right: -25px;
//   bottom: -25px;
//   top: auto;
//    border-radius:  12rem 12rem 0 0;
// }

// .card-one {
//   position: relative;
//   border: 1px solid #d9d9d9;
//   border-radius: 15px;
//   height: 180px;
//   align-items: center;
// }


// .coupon .kanan {
//     border-left: 1px dashed #ddd;
//     width: 40% !important;
//     position:relative;
// }

// .coupon .kanan .info::after, .coupon .kanan .info::before {
//     content: '';
//     position: absolute;
//     width: 20px;
//     height: 20px;
//     background: #dedede;
//     border-radius: 100%;
// }
// .coupon .kanan .info::before {
//     top: -10px;
//     left: -10px;
// }

// .coupon .kanan .info::after {
//     bottom: -10px;
//     left: -10px;
// }
// .coupon .time {
//     font-size: 1.6rem;
// }




//  @import url('https://fonts.googleapis.com/css?family=Oswald');

//         * {
//             margin: 0;
//             padding: 0;
//             border: 0;
//             box-sizing: border-box
//         }

//         body {
//             background-color: #ffff;
//             font-family: arial
//         }

//         .fl-left {
//             float: left
//         }

//         .fl-right {
//             float: right
//         }

//         h1 {
//             text-transform: uppercase;
//             font-weight: 900;
//             border-left: 10px solid #fec500;
//             padding-left: 10px;
//             margin-bottom: 30px
//         }

//         .row {
//             overflow: hidden
//         }

//         .card {
//             display: table-row;
//             width: 49%;
//             background-color: #F2F3F4;
//             color: #989898;
//             margin-bottom: 10px;
//             font-family: 'Oswald', sans-serif;
//             text-transform: uppercase;
//             border-radius: 15px;
//             position: relative;
//             height: auto;
//             border: 1px solid #D9D9D9;
//         }

//         .card+.card {
//             margin-left: 2%
//         }

//         .date {
//             display: table-cell;
//             width: 35%;
//             position: relative;
//             text-align: center;
//             border-right: 2px dashed #ffffff
//         }

//         .date:before,
//         .date:after {
//             content: "";
//             display: block;
//             width: 30px;
//             height: 30px;
//             background-color: #ffffff;
//             position: absolute;
//             top: -15px;
//             right: -15px;
//             z-index: 1;
//             border-radius: 50%
//         }

//         .date:after {
//             top: auto;
//             bottom: -15px
//         }

//         .date time {
//             display: block;
//             position: absolute;
//             top: 50%;
//             left: 50%;
//             -webkit-transform: translate(-50%, -50%);
//             -ms-transform: translate(-50%, -50%);
//             transform: translate(-50%, -50%)
//         }

//         .date time span {
//             display: block
//         }

//         .date time span:first-child {
//             color: #2b2b2b;
//             font-weight: 600;
//             font-size: 250%
//         }

//         .date time span:last-child {
//             text-transform: uppercase;
//             font-weight: 600;
//             margin-top: -10px
//         }

//         .card-cont {
//             display: table-cell;
//             width: 100%;
//             font-size: 85%;
//             padding: 10px 10px 20px 10px
//         }

//         .card-cont h3 {
//             color: #3C3C3C;
//             font-size: 130%
//         }

//         .row:last-child .card:last-of-type .card-cont h3 {
//             text-decoration: line-through
//         }

//         .card-cont>div {
//             display: table-row
//         }

//         .card-cont .even-date i,
//         .card-cont .even-info i,
//         .card-cont .even-date time,
//         .card-cont .even-info p {
//             display: table-cell
//         }

//         .card-cont .even-date i,
//         .card-cont .even-info i {
//             padding: 5% 5% 0 0
//         }

//         .card-cont .even-info p {
//             padding: 0px 50px 0 0
//         }

//         .card-cont .even-date time span {
//             display: block
//         }

//         .card-cont a {
//             display: block;
//             text-decoration: none;
//             width: 80px;
//             height: 30px;
//             background-color: #D8DDE0;
//             color: #000000;
//             text-align: center;
//             line-height: 30px;
//             border-radius: 4px;
//             position: absolute;
//             right: 10px;
//             bottom: 10px
//         }

//         .row:last-child .card:first-child .card-cont a {
//             background-color: #037FDD
//         }

//         .row:last-child .card:last-child .card-cont a {
//             background-color: #F7DC6F
//         }

//         @media screen and (max-width: 960px) {
//             .card {
//                 display: block;
//                 float: none;
//                 width: 100%;
//                 margin-bottom: 10px
//             }

//             .card+.card {
//                 margin-left: 0
//             }

//             .card-cont .even-date,
//             .card-cont .even-info {
//                 font-size: 75%
//             }
//         }