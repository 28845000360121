 .customMarker {
     position: absolute;
     cursor: pointer;
     background: #F9A30B;
     width: 40px;
     /* Adjust the size of the circle */
     height: 40px;
     /* Adjust the size of the circle */
     /*margin-left: -50px;*/
     margin-left: -25px;
     /* -width/2 */
     /*margin-top: -110px;*/
     margin-top: -60px;
     /* -height/2 */
     border-radius: 50%;
     /* Use border-radius to create a circle */
     padding: 0px;
     display: flex;
     align-items: center;
     justify-content: center;
 }

 .customMarker:after {
     content: "";
     position: absolute;
     bottom: -8px;
     left: 7px;
     border-width: 13px 13px 0;
     border-style: solid;
     border-color: #F9A30B transparent;
     display: block;
     width: 0;
 }

 .customMarker img {
     width: 32px;
     /* Adjust the size of the image inside the circle */
     height: 32px;
     /* Adjust the size of the image inside the circle */
     margin: 5px;
     border-radius: 50%;
 }

 .customMarker.shop {
    background: #DB5A00;
    border-color: #DB5A00 transparent;
}

.customMarker.shop:after {
    border-color: #DB5A00 transparent;
}

.customMarker.place {
    background: #F9A30B;
    border-color: #F9A30B transparent;
}

.customMarker.place:after {
    border-color: #F9A30B transparent;
}

 .customMarker.product {
    background: #4CAF50; /* สีพื้นหลังสำหรับ product */
}

.customMarker.product:after {
    border-color: #4CAF50 transparent; /* สีเส้นขอบสำหรับ product */
}

.customMarker.service {
    background: #2196F3; /* สีพื้นหลังสำหรับ service */
}

.customMarker.service:after {
    border-color: #2196F3 transparent; /* สีเส้นขอบสำหรับ service */
}

 .gm-style-iw {
     max-height: none !important;
     padding-inline-end: unset !important;
 }